import { navigate } from "gatsby"
import React, { useEffect } from "react"
import styled from "styled-components"

import SEO from "../components/seo"

const Root = styled.div`
  width: 100%;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
`

const NotFoundPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => navigate("/"), 2000)

    return () => clearTimeout(timer)
  })

  return (
    <>
      <SEO title="404: Not found" />
      <Root>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Root>
    </>
  )
}

export default NotFoundPage
